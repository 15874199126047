@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Glegoo:wght@400;700&display=swap');



.contact input, .contact textarea {
	@apply border focus:outline-none py-4 px-5 rounded text-customGray
}



html {
	scroll-behavior: smooth;
}


* {
  font-family: "Glegoo", sans-serif;
}


.flip-effect{
	perspective: 1000px; /* Remove this if you don't want the 3D effect */

}

/* This container is needed to position the front and back side */
.flip-effect-inner {
	position: relative;
	transition: transform 0.8s;
	transform-style: preserve-3d;
}

.flip-effect:hover  .flip-effect-inner{
	transform: rotateY(360deg);
}

.opensans {
	font-family: "Open Sans",sans-serif;
}

